<template>
<div class="page-container">
	<p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
	<div class="page-desc">
		<div v-if="search_key.type == 1">如何设置资产分类</div>
	    <div v-else>如何设置耗材分类</div> 
	</div>  
	<div class="cate-box">
		<div class="tree-box">
			<el-tree :data="treeData" :props="defaultProps" node-key="id" @node-click="handleNodeClick"></el-tree>
		</div>
		<div class="table-box">
			<div class="search-form">
			    <el-form ref="form" :inline="true"  :model="search_key" prop="">
			        <el-form-item label="">
			            <el-input v-model="search_key.name" placeholder="输入关键字进行查询"></el-input> 
			        </el-form-item>
			        <el-button type="primary" @click="getData()">查询</el-button>
						 <el-button type="primary" @click="handleExport()">导出全部</el-button>
			    </el-form> 
			 </div>
			<div style="margin-bottom:10px;">
			     <el-button size="small" type="" @click="handleAdd()">添加</el-button>
			 </div> 
			<el-table
			       :data="tableData" 
				   height="600px"
			       style="width:100%">
			        <el-table-column    
			           prop="cate_code"
			           label="分类编码">
			       </el-table-column>
			       <el-table-column    
			           prop="name"
			           label="分类名称">
			       </el-table-column>
			       <el-table-column    
						prop="parent_name"
			           label="上级分类">
					   <template slot-scope="scope">
						    <span >{{scope.row.parent_name || '-'}}</span>
					   </template>
			       </el-table-column>
			         <el-table-column    
			           prop="status"
			           label="状态">
			            <template slot-scope="scope">
			                <span v-if="scope.row.status == 1" style="color:#67C23A">启用</span>
			                <span v-else style="color:#909399">禁用</span>
			            </template>
			       </el-table-column>
				   <template v-if="search_key.type == 1">
				   <el-table-column
				       prop="card_id"
				       label="绑定的资产卡片">
					   <template slot-scope="scope">
						   <span>{{scope.row.card_id || '-'}}</span>
					   </template>
				   </el-table-column>
				   </template>
				   <el-table-column
				       prop="sort"
				       label="排序">
				   </el-table-column>
			      <el-table-column
			          prop="remark"
			          label="备注">
					  <template slot-scope="scope">
					  		<span>{{scope.row.remark || '-'}}</span>
					  </template>
			      </el-table-column>
			       <el-table-column
			       fixed="right"
			       width="220px"
			       label="操作">
			       <template slot-scope="scope">
						<el-button   class="btn"  size="small" @click="handleAdd(scope.row.id)">编辑</el-button>
						<el-button   class="btn"  size="small" type="primary" plain  @click="handleStatus(scope.row)">{{scope.row.status == 1 ? '禁用' :'开启'}}</el-button>
					    <el-button  class="btn"  size="small" type="danger" plain  @click="handleDel(scope.row)">删除</el-button>
			       </template>
			       </el-table-column>  
			</el-table>
			<AddVue ref="childDialog"></AddVue>
		</div>
	</div>
</div>
</template>

<script>
import AddVue from './Add.vue'
export default {
	 inject:['reload'],
	 components:{
	     AddVue,
	 },
    data() {
        return {
			 tableData:[],
			 search_key:{
				 name:'',
				 pid:'',
				 type:1
			 },
			 treeData:[],
			 total:0,
			 defaultProps:{
				 label:function(data,node){
					 return data.cate_code+'-'+data.name
				 }
			 }	
        }
    },
	watch:{
		$route:{
			immediate:true,
			handler(to,from){
				// 1-资产分类  2-耗材分类
				this.search_key.type =  to.meta.category_type
				this.getTree()
				this.getData()
			}
	   } 	
	},
	
    created() {
		// this.getTree()
		// this.getData()
    },
    mounted() {

    },
    methods:{
		getTree(){
			this.$api.get('AssetCategory/treeData',{type:this.search_key.type},res=>{
				if(res.code == 200){
					this.treeData =  res.data.tree_list
				}
			})
		},
		getData(){
			this.$api.post('AssetCategory/index',this.search_key,res=>{
				if(res.code == 200){
					this.tableData = res.data.list??[]
					this.total  = res.data.count					
				}
			})
		},
		handleStatus(row){
			let s   = row.status == 1?0:1
			this.$api.put('AssetCategory/updateStatus',{id:row.id,status:s},res=>{
				if(res.code == 200){
					this.$message.success(res.msg)
					this.reload()
				}else{
					this.$message.error(res.msg)
				}
			})
		},
		handleAdd(id=0){
			console.log('add:',this.search_key.type)
			this.$refs.childDialog.handleAdd(id,this.treeData,this.search_key.type)
		},
		
		handleDel(row){
			this.$alert("确定删除该分类数据吗？", "删除提示", {
			    showConfirmButton: true,
			    showCancelButton: true,
			    callback: action => {
			        if (action == "confirm") {
			            this.$api.delete("AssetCategory/del", { "id": row.id }, res => {
			                if (res.code == 200) {
			                    this.$message.success(res.msg);
			                    this.reload();
			                }
			            });
			        }
			        else if (action == "cancel") {
			            this.reload();
			        }
			    }
			});	
		},
		handleExport(){
			location.href = '/api/admin/AssetCategory/export?type='+this.search_key.type
		},
		 handleNodeClick(data) {
		    this.search_key.pid = data.id
			this.getData()
		}
    },
}
</script>
<style lang="scss">
	.tree-box {
		.el-tree{
			.el-tree-node__label{
				color: #606266 !important;
				font-size: 12px !important; 
			}
		}
	}
</style>
<style scoped lang="scss">
	.cate-box{
		width: 100%;
		height: 100vh;
		display: flex;
		.tree-box{
			margin-right: 20px;
			min-width: 280px; 
			height: 100vh;
			border:1px solid #DCDFE6;
			border-radius: 5px;
			background: white;
		}
		.table-box{
			width: 80%;
		}
	}
</style>