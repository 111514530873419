<template>
	<div>
		<!-- 添加弹窗 -->
		<el-dialog :title="form.type == 1 ? '添加资产分类':'添加耗材分类'" :visible.sync='dialogAddVisible' width="40%" :close-on-click-modal="false">
			<el-form ref="form" :model="form" prop="" :rules="rules" label-width="100px">
				<el-form-item label="分类编码" prop="cate_code">
					<el-input v-model="form.cate_code"></el-input>
					<span style="color: #F56C6C;font-size: 12px;font-weight: bold;">(支持输入数字、大小写字母、“-” )</span>
				</el-form-item>
				<el-form-item label="分类名称" prop="name"> 
					<el-input v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item label="上级分类：" prop="pid">
					<el-select ref="treeSelect" v-model="form.parent_name" placeholder="请选择" clearable style="width: 100%;" @change="handleSelect">
						<el-option value="0" label="无上级"></el-option>
						<el-tree node-key="id" :current-node-key="form.pid" :data="treeList" :props="treePorps" highlight-current ref="tree"
							@node-click="handleNodeClick"> </el-tree>
					</el-select>
			 </el-form-item>
				<el-form-item label="排序">
					<el-input v-model="form.sort" type="number" @input="changeNumber"></el-input>
					 <span style="color: #909399;font-size: 12px;">填入序号，从大到小排序</span>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input type="textarea" rows="5" v-model="form.remark"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogAddVisible = false">取 消</el-button>
				<el-button type="primary" @click="confirm()">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		inject: ['reload'],
		data() {
			return {
				dialogAddVisible: false,
				form: {
					id: '',
					cate_code: '',
					name: '',
					parent_name: '',
					pid: 0,
					sort: 0,
					remark: '',
					type:1
				},
				treeList: [], //分类目录
				rules: {
					cate_code: [{
						required: true,
						message: '请输入分类编码',
						trigger: 'blur'
					}],
					name: [{
						required: true,
						message: '请输入分类名称',
						trigger: 'blur'
					}],
					pid: [{
						required: true,
						message: '请选择上级分类',
						trigger: 'blur'
					}],
				},
				treePorps: {
					label: "name"
				}
			}
		},
		created() {

		},
		mounted() {

		},
		watch: {
			'form.pid'() {
				this.$nextTick(() => {
					
					this.$refs.treeSelect.visible = false
				})
			}
		},
		methods: {
			changeNumber(val){
				if(typeof val === 'undefined' || val === null || val === ''){
					this.form.sort = 0
				}
			},
			handleAdd(id, treeList,type) {
				this.treeList = treeList
				this.form.type = type
				if (id < 1) {
					this.$api.get('AssetCategory/add', null, res => {
						if (res.code == 200) {
							this.dialogAddVisible = true
						}
					})
				} else {
					this.handleEdit(id)
				}
			},
			handleEdit(id) { //编辑数据
				this.$api.get('AssetCategory/edit', {
					'id': id
				}, res => {
					if (res.code == 200) {
						this.form = res.data.info
						this.dialogAddVisible = true
						this.$nextTick(function(){
							// this.form.pname =  'xxxxxxxx' 
							this.$refs.tree.setCurrentKey(res.data.info.pid)
						})
					} else {  
						this.$message.error(res.msg)
					}
				})
			},
			confirm() {
				let url = 'AssetCategory/add'
				if (this.form.id) {
					url = 'AssetCategory/edit'
				}
		 	this.$api.post(url, this.form, res => {
					if (res.code == 200) {
						this.dialogAddVisible = false
						this.$message.success(res.msg)
						this.reload();
						this.$refs["form"].resetFields();
								
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			handleSelect(val){
				this.form.pid =  val
			},
			handleNodeClick(data) {
				console.log("click-tree:",data)
				this.form.parent_name = data.name
				this.form.pid = data.id
			}
		},
	}
</script>
<style lang="scss">
	.el-tree-node__expand-icon {
		font-size: 10px;
	}
</style>
